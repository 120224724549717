@font-face {
  font-family: 'TheJamsil5Bold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/TheJamsil5Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  src: local('Pretendard Thin'), local('Pretendard-Thin'),
  url('../assets/fonts/Pretendard-Thin.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  src: local('Pretendard Extra Light'), local('Pretendard-ExtraLight'),
  url('../assets/fonts/Pretendard-ExtraLight.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  src: local('Pretendard Light'), local('Pretendard-Light'),
  url('../assets/fonts/Pretendard-Light.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-Light.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src: local('Pretendard Regular'), local('Pretendard-Regular'),
  url('../assets/fonts/Pretendard-Regular.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src: local('Pretendard Medium'), local('Pretendard-Medium'),
  url('../assets/fonts/Pretendard-Medium.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  src: local('Pretendard Semi Bold'), local('Pretendard-SemiBold'),
  url('../assets/fonts/Pretendard-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  src: local('Pretendard Bold'), local('Pretendard-Bold'),
  url('../assets/fonts/Pretendard-Bold.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  src: local('Pretendard Extra Bold'), local('Pretendard-ExtraBold'),
  url('../assets/fonts/Pretendard-ExtraBold.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  src: local('Pretendard Black'), local('Pretendard-Black'),
  url('../assets/fonts/Pretendard-Black.woff2') format('woff2'),
  url('../assets/fonts/Pretendard-Black.woff') format('woff');
}
